import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { Router } from "@reach/router"
import styled from "styled-components"
import tw from "twin.macro"

import Layout from "../components/layout"
// import SEO from "../components/seo";

const cv =  require("../images/CV.png")

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1d;
  padding: 2rem 1rem;
  min-height: 100vh;
  ${tw`md:(pl-32 pr-32)`}
`

const CvPage = () => {
  return (
    <Layout>
      {/* <SEO title="Hello world!" /> */}
      <Flex>
        <img src={cv} alt="CV"/>
      </Flex>
    </Layout>
  )
}

export default CvPage
